// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-tsx": () => import("/Users/macuser/Desktop/Programming/personal_website/src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-pages-404-tsx": () => import("/Users/macuser/Desktop/Programming/personal_website/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/Users/macuser/Desktop/Programming/personal_website/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("/Users/macuser/Desktop/Programming/personal_website/src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/macuser/Desktop/Programming/personal_website/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("/Users/macuser/Desktop/Programming/personal_website/src/pages/Page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-photography-tsx": () => import("/Users/macuser/Desktop/Programming/personal_website/src/pages/photography.tsx" /* webpackChunkName: "component---src-pages-photography-tsx" */),
  "component---src-pages-projects-tsx": () => import("/Users/macuser/Desktop/Programming/personal_website/src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/macuser/Desktop/Programming/personal_website/.cache/data.json")

